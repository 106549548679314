import { useState } from 'react';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { forgetDeleteDocumentation, reingestDocuments } from '../../api/patiaAI';
import { useSnackbar } from '../../context/SnackbarContext';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useUserContextController } from '../../context/UserContext';

interface DocumentListActionsProps {
  document: any;
  afterDelete: () => void;
}

const DocumentListActions = (props: DocumentListActionsProps) => {
  const [userContext] = useUserContextController();
  const { user, slackCredentials, notionCredentials, googleCredentials, agentName } = userContext;
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const disableButton = props.document.actor_id !== user?.user_id;
  const { showSnackbar } = useSnackbar();
  
  const getAccessToken = (doc_type: string) => {
    switch (doc_type) {
      case 'notion_pages':
        return notionCredentials?.access_token || '';
      case 'slack_channels':
        return slackCredentials?.access_token || '';
      case 'gdoc':
        return googleCredentials?.access_token || '';
      default:
        return '';
    }
  }

  const handleDeleteIngestion = async () => {
    setIsDeleting(true);
    const delete_flag = false;
    forgetDeleteDocumentation(agentName || '', props.document.id, delete_flag)
      .then(() => {
        setIsDeleting(false);
        showSnackbar('Successfully deleted document', 'success');
        props.afterDelete();
      })
      .catch(() => {
        setIsDeleting(false);
        showSnackbar('Failed to delete document', 'error');
      });
  };

  const handleRefreshIngestion = async () => {
    setIsRefreshing(true);
    reingestDocuments(agentName || '', getAccessToken(props.document.doc_type), [props.document])
      .then(() => {
        setIsRefreshing(false);
        showSnackbar('Successfully reingested document', 'success');
      })
      .catch(() => {
        setIsRefreshing(false);
        showSnackbar('Failed to reingest document', 'error');
      });
  };

  const getRefreshButtonTitle = () => {
    if (getAccessToken(props.document.doc_type) === '') {
      return 'Document cannot be reingested';
    } else if (disableButton) {
      return 'You are only allowed to reingest documents that you have created';
    } else if (isDeleting) {
      return 'Refresh not allowed while deletion is in progress';
    } else {
      return null
    }
  };

  const getDeleteButtonTitle = () => {
    if (isRefreshing) {
      return 'Delete not allowed while refresh is in progress';
    } else if (props.document.doc_type === 'dokai_skill') {
      return 'Unable to delete document';
    } else {
      return null;
    }
  }

  return (
    <>
      {/* Refresh Button */}
      <Tooltip
        title={disableButton || getAccessToken(props.document.doc_type) === '' || isDeleting ? getRefreshButtonTitle() : null}
        placement="top"
      >
        <Button
          sx={{
            fontSize: '12px',
            width: '90px',
            marginLeft: '5px',
            textAlign: 'center !important',
            textTransform: 'capitalize',
            color: (isRefreshing || isDeleting || disableButton || getAccessToken(props.document.doc_type) === '') ? '#CCCCCC' : '#49BAFD',
            cursor: (isRefreshing || isDeleting || disableButton || getAccessToken(props.document.doc_type) === '') ? 'default' : 'pointer',
            border: (isRefreshing || isDeleting || disableButton || getAccessToken(props.document.doc_type) === '') ? '1px solid #CCCCCC' : '1px solid #49BAFD',
            borderRadius: '20px 20px 20px 20px',
          }}
          startIcon={isRefreshing ? null : <RefreshIcon />}
          onClick={() => {
            if (!isRefreshing && !isDeleting && !disableButton && getAccessToken(props.document.doc_type) !== '') {
              handleRefreshIngestion();
            }
          }}
        >
          {isRefreshing ? <CircularProgress size={21} /> : 'Refresh'}
        </Button>
      </Tooltip>
      {/* Delete Button */}
      <Tooltip
        title={disableButton || isRefreshing ? getDeleteButtonTitle() : null}
        placement="top"
      >
        <Button
          sx={{
            fontSize: '12px',
            width: '90px',
            marginLeft: '5px',
            textAlign: 'center !important',
            textTransform: 'capitalize',
            color: (isDeleting || isRefreshing || disableButton) ? '#CCCCCC' : '#DC3545',
            cursor: (isDeleting || isRefreshing || disableButton) ? 'default' : 'pointer',
            border: (isDeleting || isRefreshing || disableButton) ? '1px solid #CCCCCC' : '1px solid #DC3545',
            borderRadius: '20px 20px 20px 20px',
          }}
          startIcon={isDeleting ? null : <DeleteOutlineIcon />}
          onClick={() => {
            if (!isDeleting && !isRefreshing && !disableButton) {
              handleDeleteIngestion();
            }
          }}
        >
          {isDeleting ? <CircularProgress size={21} /> : 'Delete'}
        </Button>
      </Tooltip>
    </>
  );
};

export default DocumentListActions;