import { Message } from '../types/message'
import { BASE_URL } from './constants'; 
import { Cookies } from 'react-cookie';

const getUserSessionCookie = () => {
    const cookies = new Cookies();
    return cookies.get('user_session_token');
}

export async function getAIAssistantResponse(messages: Message[], addtionalData: object, agentName: string) : Promise<Record<string, any>> {  
  const url = `${BASE_URL}/ai_agent/${agentName}/chat`;

  // Convert message list into OpenAI chat format
  const reformattedMessages = messages.map((msg) => ({
    role: msg.sender === agentName ? 'system' : 'user',
    content: msg.text,
  }));

  const requestData = {  
      messages: reformattedMessages,
      additional_data: addtionalData,
  };  

  try {  
      const response = await fetch(url, {  
          method: 'POST',  
          headers: {  
              'Content-Type': 'application/json',  
          },  
          body: JSON.stringify(requestData)  
      });  

      if (!response.ok) {  
          throw new Error('Failed to fetch data');  
      }  

      const responseData = await response.json();
      return {
        text: responseData["response"],
        sourceNodes: responseData["sourceNodes"].map((node: Record<string, any>) => ({
            fileName: node.file_name,
            fileType: node.file_type,
            fileUrl: node.file_url,
        })),
        instructions: responseData["instructions"],
      };
  } catch (error) {  
      throw error;  
  }  
}  

export async function ingestDocument(
    agentName: string,
    fileIds: string[],
    docType: string,
    access_token: string,
    docs_metadatas: object[],
) {
    const user_session_token = getUserSessionCookie();
    const url = `${BASE_URL}/ai_agent/${agentName}/ingest`;
    const requestData = {
        ids: fileIds,
        doc_type: docType,
        access_token: access_token,
        docs_metadatas: docs_metadatas
    };
    
    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user_session_token}`,
        },
        body: JSON.stringify(requestData)
        });
    
        if (!response.ok) {
        throw new Error('Failed to fetch data');
        }
    
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        throw error;
    }
}

export async function getIngestedDocuments(
    agentName: string,
) {
    const url = `${BASE_URL}/ai_agent/${agentName}/ingest`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        
        const responseData = await response.json();
        return responseData.map((doc: any) => ({
            id: doc['_id'],
            name: doc['_name'],
            url: doc['_url'],
            actor_id: doc['actor_id'],
            doc_type: doc['_doc_type']
        }));
    } catch (error) {
        throw error;
    }
}

export async function reingestDocuments(
    agentName: string,
    accessToken: string,
    documentList: object[],
) {
    const url = `${BASE_URL}/ai_agent/${agentName}/refresh_documents`;
    const documents_infos = documentList.map((doc: any) => ({
        ids: [doc['id']],
        access_token: accessToken,
        doc_type: doc['doc_type']
    }))
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(documents_infos),
        });

        if (!response.ok) {
            throw new Error('Failed to reingest documents');
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error("Error reingesting documents:", error);
        throw error;
    }
}

// TODO: use this instead of generateDocumentation (or deprecate - accomodate change from BE)
export async function teachNewSkill(
    agentName: string, 
    skill_id: string,
) {
    const url = `${BASE_URL}/ai_agent/${agentName}/teach?skill_id=${skill_id}`;
    
    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        });
    
        if (!response.ok) {
        throw new Error('Failed to fetch data');
        }
    
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        throw error;
    }
}

export async function forgetDeleteDocumentation(
    agentName: string, 
    skill_id: string,
    delete_skill: boolean
) {
    const url = `${BASE_URL}/ai_agent/${agentName}/teach?skill_id=${skill_id}&delete_skill=${delete_skill}`;

    try {
        const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        });
    
        if (!response.ok) {
        throw new Error('Failed to fetch data');
        }
    
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        throw error;
    }
}

// TODO: set instructions into recordedActions array (or use teachNewSkill endpoint - accomodate the change from BE)
export async function generateDocumentation(
    context: string,
    additionalInformation: string,
    instructions: string[]
) {
    const url = `${BASE_URL}/generate_documentation`;
    const requestData = {
        context,
        "additional_information": additionalInformation,
        "instructions": instructions
    };
    
    try {
        const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData)
        });
    
        if (!response.ok) {
        throw new Error('Failed to fetch data');
        }
    
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        throw error;
    }
}